<template>
  <div class="card">
    <div
        style="display: flex;border: 1px solid #dee2e6;justify-content: space-between;border-radius: 3px;align-items: center">
      <Breadcrumb style="border: none" :model="type==1?items1:(type==2?items2:(type==3?items3:items4))"/>
      <Button style="height: 33px" class="p-button-text p-mr-2" @click="$router.go(-1)">返回</Button>
    </div>
    <div class="p-mt-3" style="width: 100%">
      <DataTable ref="dt" :value="data" v-model:selection="selectedProducts" dataKey="id"

                 :paginator="true" :rows="10" :filters="filters"
                 paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                 :rowsPerPageOptions="[5,10,25]"
                 currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计 {totalRecords} 记录">
        <template #header>
          <InputText type="text" @change="getData" v-model="name" placeholder="姓名/公司"/>
        </template>
        <template #empty>
          <p style="text-align: center">暂无数据</p>
        </template>
        <Column field="companiesName" header="企业名称" :sortable="false"></Column>
        <Column field="peopleName" header="参与人员" :sortable="false"></Column>
        <Column field="phoneNum" header="电话" :sortable="false"></Column>
        <Column field="attend" header="状态" :sortable="false">
          <template #body="d">
            <label>{{ d.data.attend == 0 ? '未确定' : (d.data.attend == 1 ? '确定' : '拒绝') }}</label>
          </template>
        </Column>
<!--        <column v-if="type!=4" field="importUser" header="重要嘉宾">-->
<!--          <template #body="d">-->
<!--            <label>{{ d.data.importUser == 0 ? '否' : '是' }}</label>-->
<!--          </template>-->
<!--        </column>-->
        <Column field="operation" header="操作" headerStyle="width:250px;" :exportable="false">
          <template #body="slotProps">
<!--            <Button v-if="type!=4" class="p-button-sm" @click="isImport(slotProps.data)">-->
<!--              {{ slotProps.data.importUser == 0 ? '设为重要嘉宾' : '取消重要嘉宾' }}-->
<!--            </Button>-->
            <Button v-if="type==1" class="p-button-sm p-ml-1" @click="confirmDeleteProduct(slotProps.data)">取消参会资格
            </Button>
            <Button v-if="type!=1" class="p-button-sm p-button-danger" @click="confirmDeleteProduct(slotProps.data)">删除
            </Button>
          </template>
        </Column>
      </DataTable>

      <Dialog v-model:visible="deleteProductDialog" :style="{width: '350px'}" header="确认" :modal="true">
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
          <span v-if="type==1&&product">你确认要取消 <b>{{ product.peopleName }}</b>的参会资格?</span>
          <span v-if="type!=1&&product">你确认要删除 <b>{{ product.peopleName }}</b>?</span>
        </div>
        <template #footer>
          <Button label="否" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
          <Button label="是" icon="pi pi-check" class="p-button-text" @click="deleteProduct(product.id)"/>
        </template>
      </Dialog>
    </div>
  </div>
  <Toast/>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      items1: [
        {label: '活动管理', to: '/activityList'},
        {label: '活动列表', to: '/activityList'},
        {label: '参与人员', to: '/participants'}
      ],
      items2: [
        {label: '问题管理', to: '/problemAllocation'},
        {label: '邀请人员列表'}
      ],
      items3: [
        {label: '邀请人员列表'}
      ],
      items4: [
        {label: '调查问卷', to: ''},
        {label: '问卷模板'},
        {label: '人员列表'}
      ],

      type: null,
      data: [],
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      selectedProducts: null,
      filters: {},
      submitted: false,
      name: null,
    }
  },
  created() {
    this.type = this.$route.query.tableType
    console.log(this.type)
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      let id = this.$route.query.id
      let tableType = this.$route.query.tableType
      this.$http.get('/activityRelease/getEventUserList', {
        params: {
          id: id,
          tableType: tableType,
          name: this.name
        }
      }).then((res) => {
        this.data = res.data
      })
    },
    isImport(data) {
      let id = data.id;
      this.$http.get('/activityRelease/isImport', {params: {id: id}}).then(() => {
        this.getData()
      })
    },
    confirmDeleteProduct(product) {
      console.log(product)
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct(id) {
      this.$http.get('/activityRelease/delPeopleList', {params: {'id': id}}).then(() => {
        this.deleteProductDialog = false;
        this.$toast.add({severity: 'success', summary: '成功', detail: '取消成功', life: 3000});
        this.getData()
      })
    },
  }
}
</script>

<style scoped>

</style>